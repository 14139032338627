import React from 'react';
import kabosu from './assets/Kabosu.jpeg';

const LandingPage = () => {
  return (
    <div className="bg-[#FFFFFF] min-h-screen">
       <div className="min-h-screen flex flex-col items-center justify-center">
            <div className="flex w-full justify-center mt-8">
                <div className="flex-1 flex justify-center">
                    <a href="https://x.com/kdogeSOL" className="text-4xl">
                        <p className="text-[#45a2ff] font-bold hover:text-[#2b72ba] text-3xl">Twitter</p>
                    </a>
                </div>
                <div className="flex-1 flex justify-center">
                    <a href="https://t.me/KDOGEPORTAL" className="text-4xl">
                        <p className="text-[#45a2ff] font-bold hover:text-[#2b72ba] text-3xl">Telegram</p>
                    </a>
                </div>
                <div className="flex-1 flex justify-center">
                    <a href="https://dexscreener.com/solana/cjnktdthmq1wtvnqv4zhz1a21ahgs3qxamgw9sa8whqs" className="text-4xl">
                        <p className="text-[#45a2ff] font-bold hover:text-[#2b72ba] text-3xl">Dexscreener</p>
                    </a>
                </div>
            </div>
            <p className="flex-1 flex flex-col items-center justify-center pt-8">
                <div className="grid grid-cols-2 items-center gap-24 h-full px-48">
                    <div>
                        <div className="flex flex-col">
                            <div className="mb-6">
                                <p className="text-3xl font-bold text-[#45a2ff]">Kabosu Do Only Good Everyday</p>
                            </div>
                            <p className="text-lg text-[#45a2ff]">
                                Kabosu in the morning of May 24,<br></br>
                                I feel into a deep sleep<br></br>
                                Not suffering and being carried by me<br></br>
                                I'm so tired of falling asleep.<br></br><br></br>

                                To all of you who have loved me for a long time,<br></br>
                                Thank you very much.<br></br>
                            </p>
                            <div className="text-[#FFFFFF] mt-4">
                                <a href="https://dexscreener.com/solana/cjnktdthmq1wtvnqv4zhz1a21ahgs3qxamgw9sa8whqs" className="border rounded rounded-xl bg-[#45a2ff] text-white text-xl md:px-6 md:py-4 px-2 py-1 inline-flex items-center hover:bg-[#2b72ba]">
                                    {/* <img src={dexscreener} className="w-6 h-6"></img> */}
                                    <p className="font-semibold">Buy $KDOGE</p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-end items-center ml-16">
                        <img src={kabosu} className="w-4/5 rounded rounded-3xl"></img>
                    </div>
                </div>
            </p>
            <footer className="w-full text-[#45a2ff] p-4 font-bold text-center">
                &copy; 2024 $KDOGE. All rights reserved.
            </footer>
        </div>
    </div>
  );
};

export default LandingPage;